import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

import styles from "../styles/index.module.css"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="トップページ" />
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        const h = Math.random() * 240;
        const bg = `hsl(${h}, 63%, 53%)`;

        return (
          <article
            className={styles.article}
            key={node.fields.slug}
          >
            <Link
              className={styles.link}
              to={node.fields.slug}
            >
              <div
                style={{
                  backgroundColor: bg
                }}
                className={styles.header}
              >
              </div>
              <div
                className={styles.container}
              >
                <h3
                  className={styles.title}
                >
                  {title}
                </h3>
                <div
                  className={styles.date}
                >
                  {node.frontmatter.date}
                </div>
              </div>
            </Link>
          </article>
        )
      })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: {frontmatter: {tags: {nin: "about"}}},
      sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            title
            description
            path
            tags
          }
        }
      }
    }
  }
`
